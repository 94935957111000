<template>
  <CHeader/>
  <div class="border-bottom border-3">
    <div class="container-fluid p-5 bg-light border">
      <h2 class="text-center">Vous souhaitez me joindre ?</h2>
      <div class="container mt-4">
        <p>Que vous cherchiez des conseils personnalisés, des recommandations spécifiques ou simplement des informations
          sur la meilleure façon de nourrir votre animal de compagnie, je suis prête à vous aider. Remplissez
          le formulaire ci-dessous pour me faire part de vos besoins et préocupations, et commencez le voyage vers une vie
          plus saine et plus heureuse pour votre compagnon poilu. 
        </p>

        <br>
        <p class="text-danger text-center">Nous sommes joignable via les formats suivants:</p>
        <ul class="list-group">
          <li class="list-group-item disabled"><strong>mail: </strong>croc4life@outlook.fr</li>
          <li class="list-group-item"><strong>Téléphone: </strong>06.13.78.59.97</li>
          <li class="list-group-item"><strong>Facebook</strong></li>
          <li class="list-group-item"><strong>Instagram</strong></li>
        </ul>
      </div>
    </div>
  </div>

    <CFooter/>
  </template>
  
  <script>
  import CHeader from '../components/Header.vue'
  import CFooter from '../components/Footer.vue'
  
  export default {
    name: 'VAbout',
    components: {
      CHeader,
      CFooter,
    },
    data() {
      return {
        name: "",
        email: "",
        phone: "",
        animal: "",
        message: "",
      };
    },
  }
  </script>
  
  <style lang="scss">
    @import "../../node_modules/bootstrap/dist/css/bootstrap.min.css";
    @import "../assets/styles/variables.scss";
  
    .step-one img, .step-two img, .step-three img {
      width: 300px;

    }

    .container-step-two {
      margin-top: 10%;
    }
  
    h2 {
      font-family: $title-font;
      color: $third-color !important;
    }

    h3 {
      font-family: $title-font;
      color: $second-color;
      margin-bottom: 20px;
    }

    h5 {
      font-family: $title-font;
      color: $fourth-color !important;
      text-align: center;
      margin-bottom: 20px !important;
    }

    .imgprice {
      width: 65px;
      height: 65px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-image: url(../assets/images/feuille.png);
      background-size: 100%;
      margin-top: 20px;
    }
  </style>
  