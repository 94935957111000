<template>
    <div class="container">
        <footer class="d-flex flex-wrap justify-content-between align-items-center py-3 my-4">
            <p class="col-md-4 mb-0 text-muted">Croc'4 Life - 2023 - Copyright © - Tous les droits réservés</p>

            <a href="/" class="col-md-4 d-flex align-items-center justify-content-center mb-3 mb-md-0 me-md-auto link-dark text-decoration-none">
            <img src="../assets/images/logo.png" alt="Croc'4 Life" class="bi me-2" width="60" height="52" />
            </a>

            <ul class="nav col-md-4 justify-content-end">
                <li class="nav-item">
                    <router-link class="nav-link px-2 text-muted" to="conditions-generales-de-vente#cgv-start">
                        CGV
                    </router-link>
                </li>
                <li class="nav-link px-2 text-muted">-</li>
                <li class="nav-item">
                    <router-link class="nav-link px-2 text-muted" to="mentions-legales#ml-start">
                        Mentions légales
                    </router-link>
                </li>
            </ul>
        </footer>
    </div>
    
</template>

<script>
export default {
    name: 'C-Footer',
}
</script>

<style lang="scss" scoped>
    @import "../assets/styles/variables.scss";

    


    // Mobiles @ tablettes
    @media (min-width: 320px) and (max-width: 990px) { 

        footer {
            flex-direction: column;
            justify-content: center;
        }

        img {
            margin-top: 40px;
        }

        ul {
            flex-direction: column;
            text-align: center;
            justify-content: center;
        }

     }
    
</style>