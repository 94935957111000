<template>
    <div class="container-services container-fluid p-5 text-center">
        <h2>Les services proposés</h2>
        <div class="list-services d-flex">
            <ul class="container-fluid container-list d-flex justify-content-center justify-content-evenly">
                <li class="card">
                    <div class="card-header">
                        <h3>Analyse de la ration</h3>
                        <span class="title-service">Croquettes ou pâtée</span>
                        <span class="title-service"></span>
                    </div>
                    <div class="card-body">
                        <ul class="list-group">
                                <li class="list-item list-unstyled text-center">Entretien de 25 min</li>
                                <li class="list-item list-unstyled text-center">Calcul des besoins</li>
                                <li class="list-item list-unstyled text-center">Analyse des aliments industriels actuels</li>
                                <li class="list-item list-unstyled text-center">Recommandations</li>
                            </ul>
                            <br>
                            <p class="text-center link-success">
                                <router-link class="nav-link" to="services#descAnalyse">En savoir plus</router-link>     
                            </p>
                            <br>
                    </div>
                </li>

                <li class="card">
                    <div class="card-header">
                        <h3>Bilan alimentaire</h3>
                        <span class="title-service">Formule 1</span>
                        <br>
                        <span>(Ration industrielle)</span>
                    </div>
                    <div class="card-body">
                        <div class="card-text text-center">

                            <ul class="list-group">
                                <li class="list-item list-unstyled text-center">Entretien de 45 min</li>
                                <li class="list-item list-unstyled text-center">Visio (ou domicile selon secteur)</li>
                                <li class="list-item list-unstyled text-center">Ration personnalisée, équilibrée</li>
                                <li class="list-item list-unstyled text-center">Accompagnement à la transition</li>
                                <li class="list-item list-unstyled text-center">Compte-rendu par e-mail</li>
                                <li class="list-item list-unstyled text-center">Suivi 15 jours (mail ou téléphone)</li>
                            </ul> 
                            <br>
                            <p class="text-center link-success">
                                <router-link class="nav-link" to="services#descForm1">En savoir plus</router-link>     
                            </p>

                        </div>
                    </div>
                </li>

                <li class="card">
                    <div class="card-header">
                        <h3>Bilan alimentaire</h3>
                        <span class="title-service">Formule 2</span>
                        <br>
                        <span>(Ration maison, mixte, pour la croissance, vieillissement...)</span>
                    </div>
                    <div class="card-body">
                        <div class="card-text">

                            <ul class="list-group">
                                <li class="list-item list-unstyled text-center">Entretien de 45 min</li>
                                <li class="list-item list-unstyled text-center">Visio (ou domicile selon secteur)</li>
                                <li class="list-item list-unstyled text-center">Ration personnalisée, équilibrée</li>
                                <li class="list-item list-unstyled text-center">Accompagnement à la transition</li>
                                <li class="list-item list-unstyled text-center">Compte-rendu par e-mail</li>
                                <li class="list-item list-unstyled text-center">Suivi 1 mois (mail ou téléphone)</li>
                            </ul> 
                            <br>
                            <p class="text-center link-success">
                                <router-link class="nav-link" to="services#descForm2">En savoir plus</router-link>     
                            </p>

                        </div>
                    </div>
                </li>

                <li class="card">
                    <div class="card-header">
                        <h3>Suivis</h3>
                        <span class="title-service"></span>
                    </div>
                    <div class="card-body">
                        <div class="card-text">

                            <ul class="list-group">
                                <li class="list-item list-unstyled text-center">Entretien de 30 min</li>
                                <li class="list-item list-unstyled text-center">Visio ou téléphone</li>
                                <li class="list-item list-unstyled text-center">Ajustement ration au besoin</li>
                                <li class="list-item list-unstyled text-center">Observation, recommendations et conseils</li>
                                <li class="list-item list-unstyled text-center">Compte-rendu par e-mail</li>
                            </ul> 
                            <br>
                            <p class="text-center link-success">
                                <router-link class="nav-link" to="services#descSuivis">En savoir plus</router-link>     
                            </p>

                        </div>
                    </div>
                </li>

                <li class="card">
                    <div class="card-header">
                        <h3>Ateliers</h3>
                        <span class="title-service"></span>
                    </div>
                    <div class="card-body">
                        <p class="card-text text-center"><i>En cours de création</i></p>
                    </div>
                </li>
                
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    name: 'C-Services',
}
</script>

<style lang="scss" scoped>
    @import "../assets/styles/variables.scss";

    .container-services {
        background-color: $first-background;
    }

    .list-services {
        margin-top: 50px;
    }

    .title-service {
        font-family: "Courgette", Arial, sans-serif;
    }

    h3 {
        font-size: 1.1em;
        margin-bottom: 0px;
    }

    .card {
        width: 20%;
    }

    .card-text {
        padding: 10px;
    }

    .card-text li {
        text-align: left;
    }
    
    .card-body {
        font-size: 0.9em;
    }

    // Mobiles @ tablettes
    @media (min-width: 320px) and (max-width: 990px) { 

        .container-list {
            flex-direction: column;

            .card {
                width: 100%;
                margin-bottom: 20px;
            }
        }
     }
    
</style>