<template>
  <CHeader/>
  <div class="">
    <div class="container-fluid p-5 bg-light border">
      <h2 class="text-center">Les services</h2>

      <div class="container container-services d-flex mt-5">
        <div class="container-inside-services w-50 pt-5">
          <p>
            Je propose une variété de services complets afin de répondre de manière optimale aux besoins nutritionnels
            de votre animal.
          </p>
          <p>
            Mon objectif est de répondre à toutes les questions que vous pouvez vous poser et garantir une alimentation 
            adaptée à votre compagnon.
          </p>
          <p class="">
            Mon approche est basée sur les exigences nutritionnelles du <strong>NRC</strong> pour les chiens et les chats,
            ainsi que sur les guides ou études scientifiques en matière de nutrition (Fediaf...).
          </p>
          <p class="text-center mt-5">
            <router-link to="contact"><button type="button" class="btn btn-success">Contactez-nous</button></router-link>
          </p>
        </div>
        <div class="step-one d-flex justify-content-center align-items-center w-50">
          <img class="rounded" src="../assets/images/7.png" alt="services adaptés" />
        </div>
      </div>

    </div>

    <div class="container-fluid container-rations p-5 d-flex align-items-start justify-content-around">
      <!-- Debut du cadre des rations -->
      <div class="ration w-40 d-flex flex-column justify-content-center">
            <h3 class="text-center">Analyse de la ration</h3>
            <div class="d-flex justify-content-center">
              <div class="card Card-rations mb-3" style="width: 25rem;">
                <div class="card-body">
                  <h5 class="card-title">Croquettes ou Pâtée</h5>
                  <ul>
                    <li>Entretien de 25 min</li>
                    <li>Calcul des besoins</li>
                    <li>Analyse des aliments industriels actuels</li>
                    <li>Recommandations</li>
                  </ul>
                  <p class="text-center">
                    <a 
                      href="#descAnalyse"
                      class="link-success link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover">Voir plus
                    </a>
                  </p>
                  <div class="d-flex justify-content-center">
                    <div class="imgprice">30€</div>
                  </div>
                </div>
            </div>
            </div>
        </div>
        <!-- Debut du cadre des bilans -->
        <div class="bilans w-60 d-flex flex-column justify-content-center align-items-center">
          <h3 class="text-center">Bilans alimentaires</h3>
          <div class="d-flex card-bilans justify-content-between">
              <br>
              <!-- Debut 1ere carte bilan -->
              <div class="card card-bilans mb-3">
                <div class="card-body">
                  <h5 class="card-title">Formule 1 : Ration industrielle</h5>
                  <p class="text-center"><i>(Animal en bonne santé)</i></p>
                  <br>
                  <ul>
                    <li>Entretien de 45 min</li>
                    <li>Visio (ou domicile selon secteur)</li>
                    <li>Ration personnalisée, équilibrée</li>
                    <li>Accompagnement à la transition</li>
                    <li>Compte-rendu par e-mail</li>
                    <li>Suivi 15 jours (mail ou téléphone)</li>
                  </ul>
                  <p class="text-center">
                    <a 
                      href="#descForm1"
                      class="link-success link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover">Voir plus
                    </a>
                  </p>
                  <div class="d-flex justify-content-center">
                    <div class="imgprice">60€</div>
                  </div>
                </div>
              </div>

              <!-- Debut 2eme carte Bilan -->
              <div class="card card-bilans mb-3">
                <div class="card-body">
                  <h5 class="card-title">Formule 2 : Ration maison, mixte, <br> pour la croissance, vieillissement...</h5>
                  <p class="text-center"><i>(Animal en bonne santé)</i></p>
                  <br>
                  <ul>
                    <li>Entretien de 45 min</li>
                    <li>Visio (ou domicile selon secteur)</li>
                    <li>Ration personnalisée, équilibrée</li>
                    <li>Accompagnement à la transition</li>
                    <li>Compte-rendu par e-mail</li>
                    <li>Suivi 1 mois (mail ou téléphone)</li>
                  </ul>
                  <p class="text-center">
                    <a 
                      href="#descForm2"
                      class="link-success link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover">Voir plus
                    </a>
                  </p>
                  <div class="d-flex justify-content-center">
                    <div class="imgprice">70€</div>
                  </div>
                </div>
              </div>
          </div>
        </div>
    </div>
  </div>


    <div class="container-fluid p-5 bg-light border">
      <!-- <h2 class="text-center">Les suivis</h2> -->
      <div class="container-two-suivis d-flex justify-content-center">
        <div class="w-50 p-5 d-flex flex-column align-items-center">
          <br>
          <div class="card card-suivis mb-3" style="width: 25rem;">
            <div class="card-body">
              <h5 class="card-title">Les suivis</h5>
              <br>
              <ul>
                <li>Entretien de 30 min</li>
                <li>Visio ou téléphone</li>
                <li>Ajustement ration au besoin</li>
                <li>Observations, recommandations et conseils</li>
                <li>Compte-rendu par e-mail</li>
              </ul>
              <p class="text-center">
                <a 
                  href="#descSuivis"
                  class="link-success link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover">Voir plus
                </a>
              </p>
              <div class="d-flex justify-content-center">
                <div class="imgprice">30€</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container container-analyses d-flex align-items-center p-5">
        <div class="step-two d-flex justify-content-center align-items-center w-50">
          <img class="rounded" src="../assets/images/fernando-jorge-bIW1a6svo1k-unsplash.jpg" alt="Analyse ration" />
        </div>
        <div id="descAnalyse" class="w-50  d-flex flex-column align-items-center">
          <h3>Analyse de la ration</h3>
          <p>
            Vous donnez des croquettes ou de la pâtée à votre animal ?
            <br>
            Vous désirez d'abord déterminer si cet aliment convient à ses besoins avant d'envisager la nécessité d'un examen
            nutritionnel plus approfondi ?
            <br>
            Contactez-moi :
            <br>
            <ul>
              <li>Réalisation d'un entretien de 25min</li>
              <li>Calculs des besoins nutritionnels spécifiques de votre animal</li>
              <li>Analyses de la composition des aliments industriels que vous lui donnez</li>
              <li>Recommandations adaptées à votre animal</li>
            </ul>
          </p>
            <div class="d-flex justify-content-center">
              <div class="imgprice">30€</div>
            </div>
            <br><br>
            <p><strong>Vous bénéficierez d'une remise de 40% sur un bilan nutritionnel si vous choisissez de 
              continuer avec cette prestation le mois suivant l'analyse.</strong></p> 
        </div>
    </div>

    <div class="container-fluid p-5 bg-light border">
      <h2 class="text-center">Bilan alimentaire</h2>
      <div class="container-formules d-flex justify-content-center">
        <div class="container-two-formules w-50 p-5 d-flex flex-column align-items-center">
         
          <div class="card mb-3" style="width: 100%;">
            <div class="card-body">
              <h5 id="descForm1" class="card-title">Formule 1 : Ration industrielle</h5>
              <p>Vous souhaitez proposer une ration 100% industrielle (croquettes ou pâtée) :</p>
              <ul>
                <li>Entretien de 45min en visio (ou à domicile : sans frais kilométriques dans un rayon de 20km autour de Pierrelatte)</li>
                <li>Profil de l'animal et historique alimentaire</li>
                <li>Evaluation de ses besoins et de ses apports énergétiques</li>
                <li>Réajustement de la ration actuelle ou sélection de nouveaux aliments adaptés</li>
                <li>Création d'une ration équilibrée et personnalisée avec des aliments industriels (croquettes, pâtée)</li>
                <li>Accompagnement à la transition</li>
                <li>Envoi d'une fiche bilan par mail</li>
                <li>Suivi durant 15 jours par mail ou téléphone</li>
              </ul>
              <div class="d-flex justify-content-center">
                <div class="imgprice">60€</div>
              </div>
            </div>
          </div>

          <div id="descForm2" class="card mb-3" style="width: 100%;">
            <div class="card-body">
              <h5 class="card-title">Formule 2 : Ration maison - mixte (industrielle + maison) - BARF</h5>
              <p>Vous souhaitez proposer une ration maison (partielle ou totale) dans la ration de votre animal en
                veillant que tous les apports nutritionnels soient respectés, et ainsi éviter les carences :
              </p>
              <ul>
                <li>Entretien de 45 min en visio (ou à domicile : sans frais kilométriques dans un rayon de 20km autour de Pierrelatte)</li>
                <li>Profil de l'animal et historique alimentaire</li>
                <li>Evaluation de ses besoins et de ses apports énergétiques</li>
                <li>Réajustement de la ration actuelle ou sélection de nouveaux aliments adaptés</li>
                <li>Création d'une ration équilibrée et personnalisée</li>
                <li>Proposition de deux recettes adaptées</li>
                <li>Accompagnement à la transition</li>
                <li>Envoi d'une fiche bilan par mail</li>
                <li>Suivi durant 1 mois par mail ou téléphone</li>
              </ul>
              
            </div>
          </div>

          <div class="card card-suivis mb-3" style="width: 100%;">
            <div class="card-body">
              <h5 id="descSuivis" class="card-title">Suivis</h5>
              <p>Le suivi concerne les clients ayant réalisé un bilan de moins d'un an. Ces suivis peuvent être nécessaires 
                à la suite de différents cas, tels que : adaptation de la ration suite à une sensibilité digestive,
                la stérilisation, la croissance, la gestation, la mise en place d'une activité sportive, une perte ou une 
                prise de poids.
                <br>
                Le suivi se fait principalement par mail ou en fonction du besoin en visio de 25min.
                <br>
                Un compte-rendu par mail sera transmis avec toutes les recommandations.
              </p>
              <div class="d-flex justify-content-center">
                <div class="imgprice">30€</div>
              </div>
            </div>
          </div>
          <p class="text-center mt-5">
            <router-link to="contact"><button type="button" class="btn btn-success">Contactez-nous</button></router-link>
          </p>

        </div>
      </div>
    </div>

    <CFooter/>
  </template>
  
  <script>
  import CHeader from '../components/Header.vue'
  import CFooter from '../components/Footer.vue'
  
  export default {
    name: 'VAbout',
    components: {
      CHeader,
      CFooter,
    }
  }
  </script>
  
  <style lang="scss">
    @import "../../node_modules/bootstrap/dist/css/bootstrap.min.css";
    @import "../assets/styles/variables.scss";
  
    .step-one img, .step-two img, .step-three img {
      width: 300px;

    }
  
    h2 {
      font-family: $title-font;
      color: $third-color !important;
    }

    h3 {
      font-family: $title-font;
      color: $second-color;
      margin-bottom: 20px;
    }

    h5 {
      font-family: $title-font;
      color: $fourth-color !important;
      text-align: center;
      margin-bottom: 20px !important;
    }

    .card {
      margin-right: 10px;
    }

    .imgprice {
      width: 65px;
      height: 65px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-image: url(../assets/images/feuille.png);
      background-size: 100%;
      margin-top: 20px;
    }


    // Mobiles @ tablettes
    @media (min-width: 320px) and (max-width: 990px) {
      .container-services, .container-rations, .card-bilans {
        width: 100%;
        flex-direction: column;
        align-items: center !important;
      }

      .container-inside-services {
        width: 100% !important;
        padding-top: 0 !important;
        margin-bottom: 30px;
      }

      .step-one {
        width: 100% !important;
      }

      .Card-rations {
        margin: 0 0 50px 0 !important;
      }

      .card-suivis {
        margin: 0 !important;
        padding: 0 !important;
      }

      .container-two-suivis {
        padding-top: 0 !important;
      }

      .container-analyses {
        flex-direction: column;
      }

      .step-two {
        margin-bottom: 40px !important;
      }

      #descAnaluse, .container-formules, .container-two-formules {
        width: 100% !important;
        padding: 0 !important;
        margin: 20px 0 0 0;
      }
    }


  </style>
  