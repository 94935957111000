<template>
    <CHeader/>
      <div class="container-fluid p-5 border-bottom border-3">
      <h2 class="text-center">Votre conseiller en Nutrition pour chiens et chats</h2>

      <div class="container container-presentation d-flex flex-column align-items-center mt-5">
        <div class="w-100 p-4 border rounded mb-5">
          <p>
            Je suis Fanny, une passionnée d'animaux depuis mon enfance. Je suis émerveillée par la façon dont nos amis
            à 4 pattes peuvent nous combler de bonheur et de joie, ainsi que par leur incroyable capacité à communiquer
            entre eux et avec nous.
          </p>
          <p>
            De nombreux animaux m'ont accompagné sur ces différentes années : chiens, chats, cochons d'Inde, poules et
            même des canards. Petite, je souhaitais déjà travailler avec les animaux, je souhaitais être maître-chien.
            Cependant, les évolutions des études et les premières opportunités de travail m'ont peu à peu éloignée de 
            ce domaine. Mais ce manque se faisait ressentir de plus en plus.
          </p>
        </div>
        <div class="step-three d-flex justify-content-center align-items-center w-50">
          <img class="rounded" src="../assets/images/3.png" alt="Fanny Lamarque" />
        </div>
      </div>

      <div class="container container-cat d-flex flex-column align-items-center mt-5 container-step-two">
        <div class="step-two d-flex justify-content-center align-items-center w-50">
          <img class="rounded" src="../assets/images/1.png" alt="eating cat" />
        </div>
        <div class="w-100 p-4 border rounded mt-5">
          <p>
            La prise de conscience a eu lieu lorsque j'ai adopté mon propre chien. Il a été diagnostiqué avec une dysplasie
            des deux hanches pendant sa croissance. En tant que propriétaire attentionnée j'ai ressenti le besoin de lui offrir
            la meilleure qualité de vie possible. Cependant, l'univers de la nutrition animale est complexe, en particulier en raison 
            de l'abondance d'informations disponibles en ligne.
          </p>
          <p>
            C'est alors que j'ai pris la décision de me plonger dans le monde de la nutrition canine et féline. J'ai suivi une
            formation en Suisse, où j'ai obtenu le diplôme de diététicienne pour chiens et chats après 1 an d'études approfondies.
            Mon engagement envers la santé et le bien-être des animaux s'est ainsi concrétisé. J'ai donc décidé par la suite de
            partager ce savoir en créant <strong class="croc4life">Croc'4 life.</strong>
          </p>
          <p>
            Aujourd'hui, mon engagement se tourne vers la santé et le bien-être de vos compagnons à 4 pattes. Je mets mon expertise
            à votre service et celui de vos animaux, en leur offant une nutrition adaptée à leurs besoins.
          </p>
        </div>
      </div>

    </div>
    <div class="container-global container-fluid p-5 bg-light border">
      <h2 class="text-center">A propos de Croc'4 life</h2>

      <div class="container container-present d-flex mt-5">


        <div class="container-c4l w-50 p-5 border rounded">
          <p>
            Pour <strong class="croc4life">Croc'4 life</strong>, chaque animal est unique avec ses propres besoins et préférences en matière
            de nutrition. Ma mission est de vous accompagner tout au long du parcours de votre animal, en offrant des
            conseils personnalisés.
          </p>
          <p>
            <strong class="croc4life">Croc'4 life</strong>, est spécialisé dans la nutrition canine et féline, est dédiée à fournir des solutions
            alimentaires sur mesure pour vos amis à 4 pattes. Je crois également en l'importance de l'écoute. Chaque animal 
            a des besoins spécifiques (race, âge, activité, antécédents médicaux, préférences alimentaires...). 
            Des rations industrielles et/ou faites maison pourront être trouvées en accord avec vos souhaits.
          </p>
        </div>
        <div class="step-one d-flex justify-content-center align-items-center w-50">
          <img src="../assets/images/6.png" alt="Alimentation personnalisée" />
        </div>
      </div>

      <div class="container d-flex container-step-two">
        <div class="step-two d-flex justify-content-center align-items-center w-50">
          <img class="rounded" src="../assets/images/2.png" alt="social" />
        </div>
        <div class="container-c4l w-50 h-50 p-5 border rounded">
          <p>
            Je propose un accompagnement complet, comprenant des consultations initiales pour évaluer les besoins spécifiques
            de votre animal, ainsi qu'un suivi attentif pour ajuster les recommandations au fil du temps.
          </p>
          <p>
            Mon approche repose sur une relation de confiance avec mes clients, afin de fournir des conseils et des 
            recommandations adaptés à chaque animal.
          </p>
          <p>
            N'hésitez pas à me contacter pour obtenir des conseils personnalisés et des recommandations. Votre confiance me
            motive à poursuivre mon engagement envers la santé et le bonheur de vos compagnons à 4 pattes.
          </p>
        </div>
      </div>

    </div>


    <CFooter/>
  </template>
  
  <script>
  import CHeader from '../components/Header.vue'
  import CFooter from '../components/Footer.vue'
  
  export default {
    name: 'VAbout',
    components: {
      CHeader,
      CFooter,
    }
  }
  </script>
  
  <style lang="scss">
    @import "../../node_modules/bootstrap/dist/css/bootstrap.min.css";
    @import "../assets/styles/variables.scss";

    .router-link-active {
      text-decoration: none;
      color: $first-color !important;
    }
  
    .step-one img, .step-two img, .step-three img {
      width: 300px;

    }

    .container-step-two {
      margin-top: 10%;
    }
  
    h2, .croc4life {
      font-family: $title-font;
      color: $third-color !important;
    }

    p {
      text-align: justify;
    }



    // Mobiles @ tablettes
    @media (min-width: 320px) and (max-width: 990px) { 

      .container-global {
        padding: 2rem !important;

        .container-present, .container-step-two {
          flex-direction: column !important;
          align-items: center !important;

          .step-two {
            margin-bottom: 20px;
          }

          .container-c4l {
            width: 100% !important;
            padding: 20px !important;
            margin-bottom: 20px !important;
          }
        }
      }
     }

     // Supp à tablette
     @media (min-width: 991px) {
        .container-presentation, .container-cat {
          flex-direction: row !important;
        }
     }

  </style>
  