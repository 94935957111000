<template>
  <CHeader/>
  <div class="">
    <div class="container-fluid p-5 bg-light border">
      <h2 id="ml-start" class="text-center">Mentions légales</h2>

      <div class="container d-flex mt-5">
        <div class="pt-5">
          <p>Identité entreprise: LAMARQUE Fanny EI, 9 rue Jean Giraudoux, 26700 Pierrelatte.</p>
          <p>
            <strong>Édition du site Internet</strong><br>
            Le site www.croc4life.fr, est édité par l’entreprise individuelle de Fanny LAMARQUE « Croc’4 life »,
            dont le siège social est situé au 9 rue Jean Giraudoux, 26700 Pierrelatte.
            <br>
            SIRET : 98020805200012
            <br>
            Numéro identification à la TVA: FR07980208052
            <br>
            CONTACT : croc4life@outlook.fr / 06.13.78.59.97
          </p>
          <br><hr><br>
          <p><strong>Rédaction du site Internet</strong></p>
          <p>
            Rédaction du site internet: Fanny LAMARQUE <br>
            Création et conception du site internet : Mickaël JONET
          </p>
          <br><hr><br>
          <p><strong>Hébergeur du site Internet</strong></p>
          <p>
            Le site www.croc4life.fr est hébergé par HOSTINGER, HOSTINGER operations, UAB, Rue Švitrigailos
            34, 03230 Vilnius, Lituanie, joignable par les moyens suivants: Téléphone : +37064503378 / Email :
            domains@hostinger.com
          </p>
          <br><hr><br>
          <p><strong>Respect de la propriété intellectuelle</strong></p>
          <p>
            Toutes les marques, photographies, textes, commentaires, illustrations, images animées ou non,
            séquences vidéo, sons, ainsi que toutes les applications informatiques qui pourraient être utilisées
            pour faire fonctionner le site et plus généralement tous les éléments reproduits ou utilisés sur le site
            sont protégés par les lois en vigueur au titre de la propriété intellectuelle. <br>

            Ils sont la propriété pleine et entière de l’Editeur ou de ses partenaires, sauf mentions particulières.
            Toute reproduction, représentation, utilisation ou adaptation, sous quelque forme que ce soit, de
            tout ou partie de ces éléments, y compris les applications informatiques, sans l’accord préalable et
            écrit de l’Editeur, sont strictement interdites. Le fait pour l’Editeur de ne pas engager de procédure
            dès la prise de connaissance de ces utilisations non autorisées ne vaut pas acceptation desdites
            utilisations et renonciation aux poursuites. <br>

            Seule l’utilisation pour un usage privé dans un cercle de famille est autorisée et toute autre
            utilisation est constitutive de contrefaçon et/ou d’atteinte aux droits voisins, sanctionnées par le
            code de la propriété intellectuelle. <br>

            La reprise de tout ou partie de ce contenu nécessite l’autorisation préalable de l’Editeur ou du
            titulaire des droits sur ce contenu.
          </p>
          <br><hr><br>
          <p><strong>Liens hypertextes</strong></p>
          <p>
            Le site peut contenir des liens hypertexte donnant accès à d’autres sites web édités et gérés par des
            tiers et non par l’Editeur. L’Editeur ne pourra être tenu responsable directement ou indirectement
            dans le cas où lesdits sites tiers ne respecteraient pas les dispositions légales.
          </p>
          <br><hr><br>
          <p><strong>Politique de confidentialité</strong></p>
          <p>
            <strong>1. COLLECTE DES RENSEIGNEMENTS PERSONNELS ET DROIT DE RETRAIT</strong><br>
            Nous collectons et stockons les informations suivantes via notre formulaire de contact/notre boite
            mail :
            <br>
            Humain:
            <ul>
              <li>Nom</li>
              <li>Prénom</li>
              <li>Adresse électronique</li>
              <li>Numéro de téléphone</li>
              <li>Adresse E-mail</li>
              <li>Genre / Sexe</li>
            </ul>
            <br>
            Animaux:
            <ul>
              <li>Nom</li>
              <li>Genre / Sexe</li>
              <li>Date de naissance</li>
            </ul>
            <br>
            Toutes les informations recueillies sont utilisées pour vous contacter à des fins de communication ou
            de prospection. <br>
            Par défaut et sauf maintien de la relation contractuelle, vos données personnelles seront détruites
            dans les trois ans. À tout moment, vous pouvez demander expressément la suppression de toutes les
            données personnelles vous concernant à l’adresse suivante : croc4life@outlook.fr
            <strong>COLLECTE DE DONNEES ET COOKIES</strong><br>
            <strong>Données à Caractère Personnel</strong><br>
            Lorsque vous utilisez notre Service, il est possible que nous vous demandions de nous fournir
            certaines données personnelles nominatives qui peuvent servir à vous contacter ou à vous identifier
            (« données à caractère personnel »). Les données personnelles nominatives peuvent comprendre:
            <ul>
              <li>Adresse e-mail</li>
              <li>Prénom et nom de famille</li>
              <li>Cookies et données d’utilisation</li>
            </ul>
            Nous pouvons utiliser vos données à caractère personnel pour vous envoyer des bulletins
            d’information, des communications commerciales ou des promotions et d’autres informations
            susceptibles de vous intéresser. Vous pouvez choisir de ne recevoir aucune communication de notre
            part ou de ne recevoir que certaines communications en cliquant sur le lien de désabonnement ou
            en suivant les instructions qui figurent dans chacun des e-mails que nous envoyons.
            <br>
            <strong>Données d’Utilisation</strong><br>
            Nous pouvons également recueillir des informations relatives au mode d’accès et d’utilisation du
            Service (« Données d’Utilisation »). Ces données d’utilisation peuvent comprendre des informations
            telles que l’adresse de protocole Internet (c.-à-d. l’adresse IP) de votre ordinateur, le type de
            navigateur, la version du navigateur, les pages de notre service que vous consultez, la date et l’heure
            de votre visite, le temps passé sur ces pages, les identifiants uniques de dispositifs ainsi que d’autres
            données de diagnostic.
            <br>
            <strong>Suivi et données de cookies</strong><br>
            Nous avons recours à des cookies et à d’autres technologies de suivi similaires pour effectuer un suivi
            des activités effectuées dans notre service, et nous conservons certaines informations.
            Les cookies sont des fichiers à faible volume de données pouvant comporter un identifiant unique
            anonyme. Les cookies sont envoyés à votre navigateur depuis un site web et sont stockés sur votre
            dispositif. D’autres technologies de suivi telles que les pixels, les balises et les scripts sont également
            utilisées pour recueillir et suivre des informations et afin d’améliorer et d’analyser notre service.
            Vous pouvez demander à votre navigateur de refuser tous les cookies ou de vous avertir lorsqu’un
            cookie est envoyé. Toutefois, si vous n’acceptez pas les cookies, il se peut que vous ne puissiez pas
            utiliser certains éléments de notre Service. <br>

            Exemples de cookies que nous utilisons :
            <ul>
              <li>Cookies de Session. Nous utilisons des cookies de session pour faire fonctionner notre service.</li>
              <li>Cookies de Préférences. Nous utilisons des cookies de préférences pour mémoriser vos
                  préférences et vos différents paramètres.</li>
              <li>Cookies de Sécurité. Nous utilisons des cookies de sécurité pour des raisons de sécurité.</li>
            </ul>

            <br>
            <strong>Opposition à l’utilisation des cookies</strong><br>
            Vous pouvez tout à fait vous opposer à l’enregistrement des cookies en le configurant via votre
            navigateur internet : Microsoft Internet Explorer ou Microsoft Edge, Google Chrome, Apple Safari ou
            Mozilla Firefox.
            Si vous décidez de désactiver les cookies, vous pourrez poursuivre votre navigation sur ce site
            internet, cependant tout dysfonctionnement provoqué sur le site par cette manipulation sera de
            votre entière responsabilité. 
            <br><br>
            <strong>4. DIVULGATION A DES TIERS</strong><br>
            Nous ne vendons pas, n’échangeons pas et ne transférons pas vos informations personnelles
            identifiables à des tiers. Cela ne comprend pas les tierce-parties de confiance qui m’aident à exploiter
            ce site Web, tant que ces parties conviennent de garder ces informations confidentielles.
            Exceptionnellement, il peut être nécessaire de partager des informations lorsque la loi ou les
            autorités judiciaires viennent à l’exiger, notamment lors de mesures concernant des activités
            illégales, des fraudes présumées, des menaces potentielles à la sécurité physique de toute personne,
            ou en cas de violations de nos conditions d’utilisation ou de droits des tiers.
            <br><br>
            <strong>5. DROIT D’ACCÈS</strong><br>
            Nous nous engageons à reconnaître un droit d’accès et de rectification aux personnes concernées
            désireuses de consulter, modifier, voire radier les informations les concernant.
            L’exercice de ce droit se fera à cette adresse : croc4life@outlook.fr
            <br><br>
            <strong>6. SÉCURITÉ DE VOS DONNÉES</strong><br>
            Les renseignements personnels que nous collectons sont conservés dans un environnement sécurisé.
            Les personnes travaillant pour nous sont tenues de respecter la confidentialité de vos informations.
            Pour assurer la sécurité de vos renseignements personnels, nous avons recours aux mesures
            suivantes:
            <ul>
              <li>Gestion des accès – personne concernée</li>
              <li>Logiciel de surveillance du réseau</li>
              <li>Sauvegarde informatique</li>
              <li>Pare-feu (Firewalls)</li>
            </ul>
            Nous nous engageons à maintenir un haut degré de confidentialité en intégrant les dernières
            innovations technologiques permettant d’assurer la confidentialité de vos transactions. Toutefois,
            comme aucun mécanisme n’offre une sécurité maximale, une part de risque est toujours présente
            lorsque l’on utilise Internet pour transmettre des renseignements personnels.
            Ce site Internet est protégé par reCAPTCHA et Google (voir leur politique de confidentialité et
            conditions d’utilisation).
            <br><br>
            <strong>7. ANALYSES</strong><br>
            Nous pouvons faire appel à des prestataires de services tiers pour surveiller et analyser l’utilisation
            de notre service.
            <br>
            <strong>Google Analytics</strong><br>
            Google Analytics est un service d’analyse web proposé par Google qui assure le suivi du trafic d’un
            site web et en rend compte. Google utilise les données recueillies pour suivre et surveiller l’utilisation
            de notre service. Ces données sont partagées avec d’autres services Google. Google peut utiliser les
            données recueillies pour contextualiser et personnaliser les annonces de son propre réseau
            publicitaire. <br>
            Vous pouvez empêcher que vos activités dans le cadre du service ne soient mises à la disposition de
            Google Analytics en installant le plug-in pour navigateur Analytics Opt out browser add-on de Google
            Analytics. Ce plugin empêche le code JavaScript de Google Analytics JavaScript (ga.js, analytics.js et
            dc.js) de partager les informations concernant les activités liées aux visites avec Google Analytics.
            Pour plus de précisions sur les pratiques de confidentialité de Google, merci de consulter la page web
            Protection de la vie privée et conditions de Google.
            <br><br>
            <strong>9. LIENS POINTANT VERS D’AUTRES SITES</strong><br>
            Il se peut que notre service contienne des liens pointant vers d’autres sites que nous n’exploitons
            pas. Si vous cliquez sur un lien de tiers, vous serez redirigé vers le site de ce tiers. Nous vous
            recommandons vivement d’examiner la politique de confidentialité de chacun des sites que vous
            consultez. <br>
            Nous n’avons aucun contrôle sur le contenu, les politiques ou pratiques de confidentialité des sites
            ou services de tiers et déclinons toute responsabilité en ce qui les concerne.
            <br><br>
            <strong>10. CONSENTEMENT</strong><br>
            En utilisant notre site, vous consentez à notre politique de confidentialité. Dans le cas contraire,
            merci de quitter celui-ci et de cesser de l’utiliser.
            <br><br>
            <strong>11. LABEL &amp; LÉGISLATION</strong><br>
            Nos engagements en matière de protection des renseignements personnels répondent aux exigences
            du règlement général de protection des données (RGPD) : texte réglementaire européen qui encadre
            le traitement des données de manière égalitaire sur tout le territoire de l’Union Européenne.
            <br><br>
            Il est entré en vigueur le 23/12/2023.
          </p>

        </div>
      </div>

    </div>

    
  </div>
  <CFooter/>
</template>
  
  <script>
  import CHeader from '../components/Header.vue'
  import CFooter from '../components/Footer.vue'
  
  export default {
    name: 'VMlegales',
    components: {
      CHeader,
      CFooter,
    }
  }
  </script>
  
  <style lang="scss">
    @import "../../node_modules/bootstrap/dist/css/bootstrap.min.css";
    @import "../assets/styles/variables.scss";
  
    .step-one img, .step-two img, .step-three img {
      width: 300px;

    }
  
    h2 {
      font-family: $title-font;
      color: $third-color !important;
    }

    h3 {
      font-family: $title-font;
      color: $second-color;
      margin-bottom: 20px;
    }

    h5 {
      font-family: $title-font;
      color: $fourth-color !important;
      text-align: center;
      margin-bottom: 20px !important;
    }

    .card {
      margin-right: 10px;
    }

    .imgprice {
      width: 65px;
      height: 65px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-image: url(../assets/images/feuille.png);
      background-size: 100%;
      margin-top: 20px;
    }
  </style>
  