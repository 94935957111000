<template>
    <CHeader/>
    <div class="Error404 d-flex flex-column justify-content-center align-items-center border-bottom border-3">
    <div class="d-flex flex-column align-items-center">
        <h3>Nous ne trouvons pas la page recherchée</h3>
        
        <h3>Verifiez que l'URL saisie ne contient pas d'erreur</h3>
    </div>
    <div class="img-error">
        <img src="../assets/images/error404.jpg" alt="Erreur 404">
    </div>
    </div>
    <CFooter/>
</template>
    
<script>
    import CHeader from '../components/Header.vue'
    import CFooter from '../components/Footer.vue'
    
    export default {
      name: 'VError',
      components: {
        CHeader,
        CFooter,
      },
    }
    </script>
    
    <style lang="scss">
      @import "../../node_modules/bootstrap/dist/css/bootstrap.min.css";
      @import "../assets/styles/variables.scss";
    
      .Error404 {
        min-height: 100vh;
      }

      .img-error img {
        width: 50vh;
      }
      
    </style>
    