<template>
    <div id="start" class="container-fluid container-expand-lg d-flex justify-content-center shadow-sm border-bottom border-2">
        <nav class="navbar container-default navbar-expand-lg">
            <div class="container-fluid">
                <router-link to="/">
                    <img class="logo-nav" src="../assets/images/logo.png" alt="Croc4life Logo" width="90">
                </router-link>
                <span class="title-nav">
                    <router-link to="/">Croc'4 life</router-link>
                </span>
                
                <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarScroll" aria-controls="navbarScroll" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>

                <div class="collapse navbar-collapse container-header" id="navbarScroll">
                    <ul class="navbar-nav me-auto my-2 my-lg-0 navbar-nav" style="--bs-scroll-height: 100px;">
                        <li class="nav-item">
                            <router-link class="nav-link" to="about">Qui suis-je</router-link>
                        </li>
                        <li class="nav-item">
                            <router-link class="nav-link" to="services">Services</router-link>
                        </li>
                        <li class="nav-item">
                            <router-link class="nav-link" to="contact">Contact</router-link>
                        </li>
                        <li class="d-flex">
                            <a 
                                href="https://www.facebook.com/profile.php?id=100093278883761"
                                target="_blank"
                                class="nav-link">
                                <i class="btn-rx bi bi-facebook"></i>
                            </a>
                            <a 
                                href="https://www.instagram.com/croc4life_nutrition_chienchat/" 
                                target="_blank"
                                class="nav-link">
                                <i class="btn-rx bi bi-instagram"></i>
                            </a>
                        </li>
                    </ul>
                    <router-link to="contact">
                        <button @mouseover="appointmentError()" @mouseout="outError()" class="btn-side btn btn-outline-success" type="submit">{{ btnData }}</button>
                    </router-link>
                </div>
            </div>
        </nav>
    </div>
</template>


<script>
export default {
    name: Headers,
    data() {
        return {
            btnData: 'Prendre rendez-vous',
        }
    },
    methods: {
        appointmentError() {
            this.btnData = 'Indisponible, contactez-nous';
        },
        outError() {
            this.btnData = 'Prenez rendez-vous';
        },
    },
}
</script>


<style lang="scss">

@import "../assets/styles/variables.scss";

.title-nav a {
    margin: 0 40px 0 10px;
    font-family: $title-font;
    color: $first-color;
    font-size: 1.6rem;
    text-decoration: none;
}

.nav-item {
    text-decoration: none !important;
    color: $first-color !important;
}

.nav-link {
    font-family: $nav-font;
    font-weight: 600;
    font-size: 1rem;
    margin: 0 0 0 10px;
    text-transform: uppercase;
    letter-spacing: 1px;
    animation-duration: 1s;
}

.btn-rx {
    font-size: 1rem;
}

.nav-link:hover {
    color: $first-color !important;
    margin-bottom: -2px;
    border-bottom: 2px solid $first-color;
}

.popup-error {
    position: absolute;
    right: 15px;
    top: 5px;
    padding: 2px;
    font-size: 0.7rem;
}


// Mobiles @ tablettes
@media (min-width: 320px) and (max-width: 990px) { 

    .btn-side {
        display: none;
    }

    .container-header {
        height: 10rem;
    }
}
</style>