<template>
    <CHeader/>
    <Presentation/>
    <Questions/>
    <Services/>
    <CFooter/>
  </template>
  
  <script>
  import CHeader from '../components/Header.vue'
  import Presentation from '../components/Presentation.vue'
  import Questions from '../components/Questions.vue'
  import Services from '../components/Services.vue'
  import CFooter from '../components/Footer.vue'
  
  export default {
    name: 'VHome',
    components: {
      CHeader,
      Presentation,
      Questions,
      Services,
      CFooter,
    }
  }
  </script>
  
  <style lang="scss">
    @import "../../node_modules/bootstrap/dist/css/bootstrap.min.css";
    @import "../assets/styles/variables.scss";
  
    #app {
      font-family: $text-font;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      color: $color-text;
    }
  
    .container-default {
      width: 1080px;
      margin: 0 auto;
    }
  
    h2 {
      font-family: $title-font;
      color: $third-color !important;
    }
  </style>
  