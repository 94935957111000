<template>
  <CHeader/>
  <div class="">
    <div class="container-fluid p-5 bg-light border">
      <h2 id="cgv-start" class="text-center">Conditions Générales de Vente</h2>

      <div class="container d-flex mt-5">
        <div class="pt-5">
          <p><strong>Dernière mise à jour le 30/10/2023</strong></p>
          <p>
            Croc’4 life se réserve la possibilité d’adapter ou de modifier à tout moment les présentes conditions
            générales de vente de prestation de service qui seront immédiatement portées à la connaissance de
            l’acheteur par une mise à jour en ligne.
            En cas de modification, il sera appliqué à chaque commande les conditions générales de prestation
            de service en vigueur au jour de la passation de la commande.
          </p>
          <br><hr><br>
          <p><strong>Article 1: définitions</strong></p>
          <p>
            Les termes et expressions utilisés dans ces CGV auront les définitions suivantes :
            Le “Prestataire” désigne la micro-entreprise EI Fanny LAMARQUE (ayant pour nom commercial
            «Croc’4 life») dont le siège est situé au :
            9 rue Jean Giraudoux, 26700 Pierrelatte. Immatriculée auprès de l’URSSAF (activité libérale non
            réglementée) sous le numéro de SIRET 98020805200012. Le Prestataire met à la disposition de ses
            clients un service client qui peut être contacté aux coordonnées suivantes :
            <br>
            <ul>
              <li>Par mail: croc4life@outlook.fr;</li>
              <li>Par téléphone: 06.13.78.59.97;</li>
              <li>Par courrier postal: 9 rue Jean Giraudoux, 26700 Pierrelatte.</li>
            </ul>
            <br>
            Le “Client” désigne toute personne, morale ou physique, achetant un service au prestataire.
            L’“Utilisateur” désigne toute personne qui se rend sur le site.
            La “Commande” désigne tout acte d’achat auprès du prestataire après validation d’un devis détaillé.
            Le “Service” désignent toutes les prestations disponibles et concernent notamment :
            <br>
            <ul>
              <li>Bilans et suivis diététiques pour chiens et chats;</li>
              <li>Analyse ration actuelle;</li>
              <li>Elaboration des rations maison pour chiens et chats;</li>
              <li>Conseils en choix d’aliments industriels pour chiens et chats;</li>
              <li>Conseils en lien avec la nutrition de manière générale;</li>
              <li>Masterclass/Atelier sensibilisation en ligne;</li>
              <li>Animation en présentiel;</li>
              <li>Rédaction d’article de blog.</li>
            </ul>
            <br>
            Les “CGV” ou “Conditions Générales de Vente” désignent les présentes conditions applicables dans la
            relation contractuelle entre le client et le prestataire.
          </p>
          <br><hr><br>
          <p><strong>Article 2: domaine d'application</strong></p>
          <p>
            L’ensemble de ces conditions s’appliquent à l’ensemble des services proposés par le prestataire. Les
            CGV sont communiquées systématiquement au client particulier consommateur. Et sur demande au
            client professionnel qui en fait la demande.
            Les présentes CGV constituent le socle unique de la relation commerciale entre le prestataire et le
            client. Elles encadrent les conditions dans lesquelles le prestataire fournit ses services au client.
            Avant toute commande, le client s’engage à avoir pris connaissance de ces CGV et les avoir
            acceptées. En cas de refus aux présentes CGV, le prestataire ne pourra pas proposer ses services
            payants au client.
            Pour pouvoir passer commande, le client déclare :
            <br>
            <ul>
              <li>Être une personne, physique ou morale, majeure au moment de la validation et d’avoir la
                  pleine capacité juridique lui permettant d’accepter ces CGV;</li>
              <li>Garantir qu’il dispose des autorisations indispensables pour utiliser le mode de paiement
                  souhaité.</li>
            </ul>
            <br>
            Ces CGV peuvent faire l’objet à tout moment de modifications, et mises à jour. La version applicable
            étant celle valable à la date de la passation de la commande et que le client déclare avoir lu.
          </p>
          <br><hr><br>
          <p><strong>Article 3: caractéristiques de la prestation de service</strong></p>
          <p>
            Les caractéristiques des services sont présentées et détaillées dans un devis remis au client. Le client
            est tenu d’en prendre connaissance avant toute passation de commande. Le choix de l’achat ainsi
            que les modalités de paiement sont de la seule responsabilité du client.
            Le client est averti que toute offre de services est susceptible d’évoluer. Seul le service choisi au
            moment de la commande est dû au client.
          </p>
          <br><hr><br>
          <p><strong>Article 4: commande</strong></p>
          <p>
            Le client remplit le formulaire de contact disponible sur le site internet www.croc4life.fr, ou bien
            envoie un message privé sur Facebook ou Instagram, via l’adresse mail croc4life@outlook.fr, ou
            encore via un appel téléphonique.
            Les commandes sont passées par le client par contact direct avec le prestataire.
            Le prestataire le recontacte ensuite par mail pour recueillir ses attentes et lui proposer un devis
            personnalisé.
            Toute commande est un acte ferme et définitif de la part du client et vaut engagement contractuel.
            Une preuve de la transaction de la commande sera ensuite disponible par une facture numérique
            que le prestataire archivera sur un support fiable et pouvant être transmise à titre de preuve au
            client.
            Le prestataire se réserve le droit d’annuler ou de refuser toute commande du client s’il existe un
            litige relatif au paiement (commande antérieure ou non).
          </p>
          <br><hr><br>
          <p><strong>Article 5: tarifs et modes de règlement</strong></p>
          <p>
            <strong>Les tarifs</strong><br>
            Les tarifs des services en vigueur sont ceux en vigueur le jour de la passation de la commande, tels
            qu’établis sur le devis envoyé au client. Le devis sera remis au client avec la méthode de calcul du
            prix lui permettant de vérifier.
            Les montants sont exprimés en Euros, HT et TTC. Le Prestataire étant un entrepreneur individuel au
            régime micro-fiscal, la TVA peut ne pas être applicable, en vertu de l’article 293B du CGI.
            Lorsque la TVA sera applicable, le prestataire indiquera les montants HT et TTC, et tiendra compte de
            la TVA applicable le jour de la passation de la commande.
            A chaque paiement, une facture est établie par le prestataire à destination du client.
            La validation de la commande rend exigible le montant total de la prestation, quand bien même un
            accord de paiement en plusieurs fois est accepté. En cas de paiement échelonné, le client s’engage à
            informer le prestataire de tout changement de coordonnées bancaires en cours d’échéance.
            Le prestataire pourra faire bénéficier au client de réduction de prix, ou remises, en fonction du
            nombre de prestations de service commandées ou en fonction de la régularité des commandes.
            Un acompte correspondant à 20% du prix total du devis peut être mis en place lors de la passation de
            la commande. Le solde du prix est payable comptant au moment de la fourniture de la facture du
            solde par le prestataire.
            <br>
            <strong>Les modes de règlement</strong>
            Les modes de règlement acceptés sont les suivants :
            <br>
            <ul>
              <li>Par virement bancaire sur le compte bancaire du Prestataire (la preuve de paiement ne se
                  fera qu’au moment de la réception effective sur le compte bancaire du prestataire);</li>
              <li>En espèces</li>
            </ul>
            <br>
            Les règlements s’effectueront soit:
            <br>
            <ul>
              <li>A l’issue de la prestation dans le cadre d’un bilan et/ou suivi diététique et/ou analyse de la
                  ration, qu’elle se déroule en visio, par téléphone ou en physique avec le client (sur place), et
                  donc avant l’envoi du compte rendu;</li>
              <li>Avant toute prestation dans le cadre d’ateliers, de masterclass, ou animation.</li>
            </ul>
            <br>
            Le prestataire se réserve la possibilité de faire évoluer les modes de règlement si la situation est
            nécessaire.
            Le prestataire ne saurait être tenu responsable en cas d’usage frauduleux par le client des moyens de
            paiements utilisés.
            Le prestataire se réserve le droit de suspendre et d’arrêter tout exécution du service, en cas de refus
            d’autorisation de paiement de la part des établissements bancaires du client. Le prestataire pourra
            contacter le client pour lui demander des pièces supplémentaires afin de régler cette situation.
            Le client ne pourra pas demander de remboursement ou d’exécution de la commande en cas
            d&#39;inexécution de paiement total ou partiel.
            Un défaut de paiement entraîne la non-rédaction du compte rendu de la consultation et le bilan
            alimentaire et reprendra uniquement si les impayés sont soldés. Des pénalités de retard pourront
            être appliquées aux taux de 6,18%. Les frais de recouvrement seront de 40€. Cette pénalité est
            calculée sur le montant de la somme restante due et court à compter de la date d’échéance sans
            qu’aucune mise en demeure préalable ne soit nécessaire (Délais de paiement entre professionnels et
            pénalités de retard | Entreprendre.Service-Public.fr).
            <strong>Procédure en cas de non-paiement</strong><br>
            Le prestataire a établi une procédure afin d’éviter tout litige pour le règlement des factures dues par
            le client. Le client est alors informé que le Prestataire suivra cette procédure :
            <br>
            <ul>
              <li>Envoi de la facture (d’acompte ou de solde) par mail au client;</li>
              <li>Respect d’un délai de 30 jours à compter de la réception de la facture;</li>
              <li>Sans règlement reçu de la part du client, le prestataire rééditera et renverra la facture au
                  client avec des pénalités de retard de 6,18% du montant total et 40€ pour frais de
                  recouvrement;</li>
              <li>Sans nouvelle ni règlement du client, le prestataire relancera par mail et par téléphone le
                  client;</li>
              <li>Sans nouvelle ni règlement du client, le prestataire informera par courrier recommandé avec
                  accusé réception, de la mise en demeure de paiement et de sa volonté de saisir le Tribunal
                  de Commerce compétent;</li>
              <li>Sans nouvelle ni règlement du client, le prestataire engagera une procédure simplifiée
                  auprès du Tribunal compétent afin d’ordonner une injonction de payer au Client.</li>
              <li>Toute prestation annulée moins de 24h avant le rendez-vous est due. Dans le cas où la
                  prestation est décalée, 50% du montant sera due à la nouvelle réservation, en tant
                  qu’acompte.</li>
              <li>Le compte rendu de la consultation et le bilan alimentaire, ne sera transmis qu’après le
                  paiement de la facturation effectué.</li>
            </ul>
          </p>
          <br><hr><br>
          <p><strong>Article 6: délai de rétractation</strong></p>
          <p>
            Le client particulier consommateur peut se rétracter dans un délai de 14 jours, à partir du jour de sa
            commande, en vertu de l’article L121-21 du Code de la Consommation. Pour exercer ce droit, le
            client devra informer le prestataire de sa décision de se rétracter au moyen d’un courrier
            recommandé avec accusé réception à l’adresse du siège du prestataire. Et, en complément, d’un mail
            à l’adresse du prestataire.
            Il est précisé qu’il s’agit d’un droit du consommateur et non d’une garantie commerciale.
            En tout état de cause, si le client souhaite bénéficier immédiatement du service du prestataire après
            la validation de la commande, le client renoncera expressément à son droit de rétractation.
            Lorsque le client reconnaît et accepte d’avoir accès au service du prestataire immédiatement après le
            paiement, en conséquence, il renonce expressément à son droit de rétractation. Le client certifie
            avoir pris connaissance des Conditions Générales de Vente, accepté les termes, et certifie renoncer
            expressément à son droit de rétractation conformément à l’article L.221-28, 13° du Code de la
            consommation. Il certifie également avoir reçu toutes les informations précontractuelles nécessaires.
          </p>
          <br><hr><br>
          <p><strong>Article 7: réalisation des prestations</strong></p>
          <p>
            Le service commandé sera assuré par le prestataire.
            Le prestataire s’engage à respecter au mieux les délais annoncés lors de la passation de la
            commande. Cependant, il ne peut en aucun cas être tenu responsable pour les retards de réalisation
            de prestation occasionnés en raison de fautes qui ne lui sont pas imputables.
            La responsabilité du prestataire ne peut être engagée pour des motifs de délai de réalisation en
            périodes de forte demande (telles que les périodes de fêtes de fin d’année).
            Le prestataire se réserve le droit de refuser de réaliser une prestation de services lorsque la
            justification est la suivante :
            <br>
            <ul>
              <li>Charge de travail actuelle du prestataire ne permettant pas d’honorer la commande;</li>
              <li>Non-respect des présentes CGV;</li>
              <li>Mauvais rapport préexistant entre le client et le prestataire;</li>
              <li>Toute situation ne respectant la charte de la communauté des nutritionnistes / diététiciens
                  pour chiens et chats présente sur le site www.croc4life.fr.</li>
              <li>Toute autre situation rendant la collaboration difficile.</li>
            </ul>
            <br>
            Ce refus entraîne le remboursement des sommes déjà réglées par le client si elles ont eu lieu.
          </p>
          <br><hr><br>
          <p><strong>Article 8: propriété intellectuelle</strong></p>
          <p>
            Les éléments, notamment mais pas exclusivement, portant sur les contenus, textes, images, vidéos,
            graphismes, logos, services, produits, noms de domaine et savoir-faire figurant sur le site et lors des
            échanges entre le client ou utilisateur et le prestataire, sont, et restent, la propriété exclusive du
            prestataire.
            L’accès au site, et aux services du prestataire, ne confèrent aucun droit au client ou utilisateur sur la
            propriété intellectuelle.
            Aucune cession de droits de propriété intellectuelle n’est réalisée au travers des présentes CGV.
            La reproduction, partielle ou totale, des éléments mis à la disposition du client ou de l’utilisateur ne
            peut être effectuée sans l’accord exprès du prestataire.
            L’utilisation, la transmission, ou la reproduction de tout ou partie des éléments, sans autorisation
            préalable, pourra faire l’objet de poursuites judiciaires.
            Le client s’engage donc à ne pas communiquer à des tiers ces éléments, et code d’accès strictement
            personnel. Le client ne dispose que du droit d’utilisation des services du prestataire.
          </p>
          <br><hr><br>
          <p><strong>Article 9: obligations et responsabilités des parties</strong></p>
          <p>
            <strong>Du prestataire</strong><br>
            Le prestataire n’est pas tenu à une obligation de résultats mais à une obligation de moyens. Cela
            signifie que le prestataire mettra en œuvre tous les moyens nécessaires pour permettre au client
            d’atteindre la promesse tenue par le service. Mais le prestataire ne fournit aucune garantie expresse
            ou implicite de résultat de la part du client.
            Le prestataire garantit livrer un service conforme au contrat, et sans vice caché, qui rendrait la
            promesse impropre à l’usage auquel le client le destine.
            La garantie du prestataire est limitée au montant HT payé par le client. Ce montant s’entend comme
            le maximum que pourraient être amenés à régler le prestataire à titre d’indemnités (dommages et
            intérêts) et de pénalités. Elle est exclue en cas de mauvaise utilisation ou d’utilisation anormale du
            service.
            Le client devra informer le prestataire de l’existence de vices dans un délai de 2 ans. Le prestataire
            fera rectifier le service jugé défectueux dans la mesure du possible.
            La responsabilité du prestataire ne pourra être engagée pour inexécution contractuelle, totale ou
            partielle, en cas d&#39;événement de force majeure, indépendant de sa volonté, et rendant impossible
            l’accès au service commandé par le client.
            Le prestataire s’engage à garder la confidentialité des informations transmises par le client. Le
            prestataire pourra demander l’accord exprès de remplir un questionnaire de satisfaction qui sera
            utilisé uniquement à des fins professionnels, ces derniers pourront pour autant être diffuser sur le
            site internet, Instagram ou Facebook. <br>
            <strong>Du client</strong><br>
            Le client s’engage à fournir tous les détails nécessaires au bon déroulement de la coopération avec le
            prestataire.
            Le client s’engage à verser la somme due au titre du service.
            Le client s’engage lors de consultation à domicile d’être en mesure de recevoir Mme LAMARQUE
            Fanny le jour et l’heure convenus. Lors de consultation en visioconférence, le client doit s’assurer au
            préalable que son matériel (smartphone, ordinateur, connexion internet, application, etc.)
            fonctionne afin de permettre à Mme LAMARQUE Fanny de pouvoir assurer la consultation via les
            moyens qui auront été communiqués lors de la prise de rendez-vous.
            Le client accepte expressément les présentes CGV. Il déclare en avoir pris connaissance et renonce à
            se prévaloir de tout autre document, notamment ses propres conditions générales d’achat.
          </p>
          <br><hr><br>
          <p><strong>Article 10: règlement des Litiges</strong></p>
          <p>
            Le droit applicable en matière de litige est régi par le droit Français. Les CGV sont rédigées en langue
            française. La nullité d’une clause contractuelle n’entraîne pas la nullité des CGV. L’inapplication,
            temporaire ou permanente, d’une ou plusieurs clauses des CGV par le prestataire ne vaudra pas
            renonciation de sa part aux autres clauses et continueront à produire leurs effets.
            Le règlement à l’amiable de tout litige est la priorité du prestataire. C’est pourquoi le client devra
            prioritairement rendre toute réclamation à la connaissance du prestataire par mail. Le prestataire
            s’engage à répondre à la réclamation du client dans un délai de 15 jours.
            Si aucune solution n’est trouvée, le client particulier consommateur peut saisir gratuitement le
            centre de médiation compétent pour traiter le litige. Conformément aux articles L.616-1 et R.616-1
            du code de la consommation, le prestataire a mis en place un dispositif de médiation de la
            consommation. L&#39;entité de médiation retenue est : Mediavet. En cas de litige, le consommateur
            pourra déposer sa réclamation sur le site : https://mediavet.net. Convention n°MEDIAVET6D623-
            3390.
            Si aucune solution n’est trouvée, le client professionnel peut saisir le tribunal compétent dont
            dépend le siège social de la société.
            Pour tout règlement en ligne de litiges, voici le lien du guichet unique pour les consommateurs de
            l&#39;Union Européenne (RLL) : https://entreprendre.service-public.fr/vosdroits/R48100
          </p>

        </div>
      </div>

    </div>

    
  </div>
  <CFooter/>
</template>
  
  <script>
  import CHeader from '../components/Header.vue'
  import CFooter from '../components/Footer.vue'
  
  export default {
    name: 'VCgv',
    components: {
      CHeader,
      CFooter,
    }
  }
  </script>
  
  <style lang="scss">
    @import "../../node_modules/bootstrap/dist/css/bootstrap.min.css";
    @import "../assets/styles/variables.scss";
  
    .step-one img, .step-two img, .step-three img {
      width: 300px;

    }
  
    h2 {
      font-family: $title-font;
      color: $third-color !important;
    }

    h3 {
      font-family: $title-font;
      color: $second-color;
      margin-bottom: 20px;
    }

    h5 {
      font-family: $title-font;
      color: $fourth-color !important;
      text-align: center;
      margin-bottom: 20px !important;
    }

    .card {
      margin-right: 10px;
    }

    .imgprice {
      width: 65px;
      height: 65px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-image: url(../assets/images/feuille.png);
      background-size: 100%;
      margin-top: 20px;
    }
  </style>
  