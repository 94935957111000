import { createRouter, createWebHistory } from "vue-router";
import VHome from "../views/Home.vue";
import VAbout from "../views/About.vue";
import VServices from "../views/Services.vue";
import VContact from "../views/Contact.vue";
import VCgv from "../views/CGV.vue";
import VMlegales from "../views/Mlegales.vue";
import Error404 from "../views/Error404.vue";

const router = createRouter({
  history: createWebHistory(),
  routes: [
    { path: "/", component: VHome },
    { path: "/about", component: VAbout },
    { path: "/services", component: VServices },
    { path: "/contact", component: VContact },
    { path: "/conditions-generales-de-vente", component: VCgv },
    { path: "/mentions-legales", component: VMlegales },
    { path: "/:pathMatch(.*)*", component: Error404 },
  ],
});

export default router;
