<template>
  <div class="button-top">
    <a class="border" id="button-back" href="#start"><img src="./assets/images/arrowback.png" alt=""></a>
  </div>
  <router-view></router-view>
</template>

<script>
// import VHome from './views/Home.vue'

export default {
  name: 'App',
  components: {
    // VHome,
  }
}
</script>

<style lang="scss">
  @import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
  @import "./assets/styles/variables.scss";

  #button-back, #button-back img {
    width: 50px;
    height: 50px;
  }

  #button-back {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #FFF;
    border-radius: 4px;
    position: fixed;
    bottom: 10px;
    right: 30px;
    z-index: 1000;
  }
</style>
