import { createApp } from "vue";
import Bootstrap from "bootstrap";
import App from "./App.vue";

import "./style.scss";
import "bootstrap/dist/css/bootstrap.css";
// import "bootstrap/dist/js/bootstrap.min.js";
import "bootstrap-icons/font/bootstrap-icons.css";

const app = createApp(App);

import router from "@/router";
app.use(router, Bootstrap);

app.mount("#app");
