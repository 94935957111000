<template>
    <div class="container-presentation d-flex justify-content-center align-items-center border-bottom">
        <video autoplay loop muted class="welcome-video">
            <source src="../assets/videos/Final.mp4" type="video/mp4">
        </video>
    </div>
</template>

<script>
export default {
    name: 'C-Presentation',
}
</script>

<style lang="scss" scoped>
    @import "../assets/styles/variables.scss";
    .container-presentation {
        width: 100%;
        height: 70vh;
        background-color: #e5e2e0;

        .welcome-video {
            position: absolute;
            height: 70vh;
            z-index: -1px;
            -webkit-box-shadow: -10px 0px 13px -7px #979797, 10px 0px 13px -7px #979797, 0px 5px 2px 1px rgba(115,115,115,0.02); 
box-shadow: -10px 0px 13px -7px #979797, 10px 0px 13px -7px #979797, 0px 5px 2px 1px rgba(115,115,115,0.02);
        }

        .title, .services {
            padding: 0 0 0 5%;
        }
        .title {

            h2 {
                color: #e3e1e1 !important;
                font-size: 2.8rem !important;
                margin: 5% 0 0 0;
                width:100%;
                display: inline-block;
                white-space: nowrap;
                animation: slash-by-left 2s;
            }
        }

        .services {
            width: 50%;
            background-color: rgba(255, 255, 255, 0.45);
            padding: 60px 60px;
            margin: 3%;
            border-radius: 10px;
            animation: slash-by-top 2s;

            h3 {
                font-family: $nav-font;
                font-size: 2.2rem;
                margin: 0 0 40px 0;
                color: #000000;
            }

            ul {
                list-style-type: none;
                padding: 0;

                li {
                    font-family: $title-font;
                    font-size: 1.6rem;
                    margin: 0 0 10px 0;

                    strong {
                        color: $second-color;
                    }

                    i {
                        margin: 0 10px 0 0;
                    }
                }
            }

            .btn-rdv {
                text-align: center;

                button {
                    margin: 30px 0;
                }
            }

            
        }

        // Mobiles @ tablettes
    @media (min-width: 320px) and (max-width: 990px) { 
        .services {
            margin: 0 auto;
        }

        .title {
            h2 {
                font-size: 1.8rem !important;
                margin: 10% 0 10% 0;
            }
        }

        .services {
            width: 95%;
            padding: 30px 30px;
            margin: 0 auto;

            h3 {
                font-size: 1.3rem;
            }

            ul {

                li {
                    font-size: 1rem;
                }
            }

            .btn-rdv {
                text-align: center;

                button {
                    margin: 30px 0;
                }
            }
        }
     }
    }

    @keyframes slash-by-left {
        0% {
            -webkit-transform: translateX(+100%);
            transform: translateX(+100%);
        }
        100% {
            -webkit-transform: translateX(0);
            transform: translateX(0);
        }
    }

    @keyframes slash-by-top {
        0% {
            -webkit-transform: translateY(+80%);
            transform: translateY(+80%);
            opacity: 0;
        }
        100% {
            -webkit-transform: translateY(0);
            transform: translateY(0);
            opacity: 100;
        }
    }

    
</style>