<template>
    <div class="container-fluid p-5 text-center border">
        <h2>Des questions ?</h2>
        <div class="container-questions">
            <ul class="list-questions list-unstyled mt-5 d-flex justify-content-center justify-content-evenly">
                <li class="comportement">
                    <div class="card" style="width: 20rem;">
                        <router-link to="/contact">
                            <img src="../assets/images/andrew-s-ouo1hbizWwo-unsplash.jpg" class="card-img-top" alt="Comportement">
                        </router-link>
                    
                        <div class="card-body">
                            <p class="card-text text-center">Transition pour <br> Chiot / Chaton <i class="bi bi-arrow-right"></i> Adulte <i class="bi bi-arrow-right"></i> Senior</p>
                        </div>
                    </div>
                </li>
                <li class="Nutrition">
                    <div class="card" style="width: 20rem;">
                        <router-link to="/contact">
                            <img src="../assets/images/ayla-verschueren-jnq52JjkLAg-unsplash.jpg" class="card-img-top" alt="Nutrition">
                        </router-link>
                        <div class="card-body">
                            <p class="card-text text-center">Croquettes, pâtées, ration maison ? <br>Quelle quantité ?</p>
                        </div>
                    </div>
                </li>
                <li class="Other">
                    <div class="card" style="width: 20rem;">
                        <router-link to="/contact">
                            <img src="../assets/images/dog-5883275_1280.jpg" class="card-img-top" alt="Other">
                        </router-link>
                        <div class="card-body">
                            <p class="card-text text-center">Autre sujet ?</p>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    name: 'C-Questions',
}
</script>

<style lang="scss" scoped>
    @import "../assets/styles/variables.scss";

    .card-text {
        font-weight: 700;
    }


    // Mobiles @ tablettes
    @media (min-width: 320px) and (max-width: 990px) { 

        .list-questions {
            flex-direction: column;
            align-items: center;

            .Nutrition, .Other {
                margin-top: 30px;
            }
        }

     }
    
</style>